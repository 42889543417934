<template>
  <div
    class="review-doc-view"
    @mouseup="endDragging"
  >
    <div
      class="left-field py-7"
      :style="{ width: `${dividerPosition}%` }"
    >
      <div v-if="external">
        <h4 class="text-h4 inline-middle">
          {{ $t("corrections.external") }}
        </h4>
        <div
          v-if="selectedFileInfo"
          style="width: 50%"
          class="font-weight-bold ellipsis"
        >
          {{ selectedFileInfo.name }}
        </div>
      </div>
      <div v-else>
        <BackLink
          v-if="!external"
          :target="backTarget"
          :text="$t('corrections.all')"
        />
        <DocNavigator
          v-model="selectedFileId"
          style="margin-top: -25px"
          :docs="files"
          :total-docs="totalFiles"
          :ordinal-selected="selectedFileIndex + 1"
          :loading="filesLoading"
          :show-loading-skeleton="selectedFileIndex === -1"
          @get-more="getFiles(numberOfFiles)"
          @get-missing="getMissingFiles"
          @step="(step) => selectedFileIndex += step"
        />
      </div>
      <div
        class="d-flex justify-end"
        :style="{'margin-top': external ? '-40px' : '7px'}"
      >
        <v-tooltip top>
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn
                class="small-button left-gap-sm"
                color="primary"
                @click="validateDocumentIfNoPendingValues"
              >
                <v-icon size="16">
                  fas fa-check
                </v-icon>
              </v-btn>
            </div>
          </template>
          {{ $t("corrections.validate") }}
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ props }">
            <div v-bind="props">
              <v-btn
                class="small-button left-gap-sm"
                color="primary"
                @click="discardDialog = true"
              >
                <v-icon size="16">
                  fas fa-trash
                </v-icon>
              </v-btn>
            </div>
          </template>
          {{ $t('corrections.discard') }}
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ props }">
            <div
              v-if="userRole === 'orgadmin'"
              v-bind="props"
            >
              <v-btn
                class="small-button left-gap-sm"
                color="primary"
                :disabled="datasets.length === 0"
                @click="copyDialog = true"
              >
                <v-icon size="16">
                  fas fa-copy
                </v-icon>
              </v-btn>
            </div>
          </template>
          {{ $t('corrections.copy') }}
        </v-tooltip>
      </div>
      <div v-if="objectValues.length > 0">
        <v-card
          class="dp-card pa-0 mt-2 object-validator-container"
          tile
        >
          <ObjectValidator
            :object-values="objectValues"
            :highlighted-object="highlightedObject"
            :current-file="selectedFileInfo || defaultFileInfo"
            @handle-add-object="handleAddObject"
            @cancel-add-object="cancelAddObject"
            @handle-object-enter="handleObjectEnter"
            @handle-object-leave="handleObjectLeave"
            @get-object-values="getObjectValues"
          />
        </v-card>
      </div>
      <div class="top-gap">
        <ReviewTextArea 
          v-for="dp in dpValues"
          :ref="`dp_${dp.data_point_id}`"
          :key="`dp_${dp.data_point_id}`"
          class="bottom-gap"
          id-name="data_point_id"
          :value="dp"
          :business-rule-strings="dataPointBRStrings[dp.data_point_id] || []"
          :list-hovered-id="listHovered && listHovered.data_point_id ? listHovered.data_point_id : 0"
          :viewer-hovered-id="viewerHovered && viewerHovered.data_point_id ? viewerHovered.data_point_id : 0"
          :reviewing-id="reviewingValue && reviewingValue.data_point_id ? reviewingValue.data_point_id : 0"
          :loading="loadingDpValue === dp.data_point_id"
          @verify="verifyValue(dp)"
          @reinit="reinitValue(dp)"
          @focus="reviewingValue = dp"
          @blur="handleBlur"
          @hover="v => listHovered = v"
        />
        <div
          v-for="group in groupValues"
          :key="group.group_id"
          :style="{ 'margin-bottom': group.config && group.config.table_display ? '20px' : '50px' }"
        >
          <div
            class="label"
            :style="{ 'margin-bottom': loadingGroupValue && (!group.config || !group.config.table_display) ? '3px' : '0px' }"
          >
            {{ group.group_name }}
            <v-icon
              v-if="loadingGroupValue && (!group.config || !group.config.table_display)"
              class="left-gap-sm"
              color="primary"
              size="20"
            >
              fas fa-spinner fa-pulse
            </v-icon>
            <v-icon
              v-else-if="group.subgroups
                && group.subgroups.length > 0
                && group.subgroups.every(subgroup => subgroup.values
                  && subgroup.values.length > 0
                  && subgroup.values.every(
                    value => value.reviewStatus === 'reviewed'
                  )
                )"
              class="valid-icon left-gap-sm inline-top"
              style="margin-top: -2px"
              size="16"
            >
              fas fa-check
            </v-icon>
            <v-tooltip
              v-else-if="group.subgroups
                && group.subgroups.length > 0
                && group.subgroups.every(subgroup => subgroup.values
                  && subgroup.values.length > 0
                  && subgroup.values.every(
                    value => value.reviewStatus === 'prevalidated'
                  )
                )"
              location="right"
            >
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="small-button prevalidated-button left-gap-sm inline-middle"
                  color="primary"
                  :disabled="!group.config
                    || !group.subgroups
                    || group.subgroups.length === 0
                    || group.subgroups.some(s => !s.values || s.values.length === 0)"
                  @click="confirmGroupValues(group)"
                >
                  <v-icon size="16">
                    fas fa-check
                  </v-icon>
                </v-btn>
              </template>
              {{ $t("dataPoints.group_prevalidated_message") }}
            </v-tooltip>
            <v-btn
              v-else
              class="small-button left-gap-sm inline-middle"
              color="primary"
              :disabled="!group.config
                || !group.subgroups
                || group.subgroups.length === 0
                || group.subgroups.some(s => !s.values || s.values.length === 0)"
              @click="confirmGroupValues(group)"
            >
              <v-icon size="16">
                fas fa-check
              </v-icon>
            </v-btn>
          </div>
          <div v-if="group.config && group.config.table_display">
            <GroupTableDisplay
              v-if="group.subgroups
                && group.subgroups.length > 0"
              class="top-gap-sm"
              :group="group"
              :file-id="selectedFileId"
              :get-group-values="getGroupValues"
              :doc-viewer-selecting="!!reviewingValue"
              :viewer-hovered="viewerHovered || {}"
              :reviewing-value="reviewingValue || {}"
              @save="verifyValue"
              @add-label="addLabel"
              @remove-subgroup="removeSubgroup"
              @value-hover="(value, subgroup) => {
                listHovered = value;
                listHoveredSubgroup = subgroup;
              }"
              @review="v => reviewingValue = v"
              @blur="handleBlur"
            />
            <v-btn
              class="top-gap-sm"
              color="primary"
              variant="outlined"
              :disabled="group.subgroups
                && group.subgroups.length > 0
                && !group.subgroups[group.subgroups.length - 1].id"
              @click="addEmptySubgroup(group)"
              rounded
            >
              <v-icon
                size="16"
                start
              >
                fas fa-plus
              </v-icon>
              {{ $t("dataPoints.add_missing_subgroup") }}
            </v-btn>
          </div>
          <div v-else-if="group.config">
            <div style="border-left: 1px rgb(var(--v-theme-grey-darken3)) solid">
              <div
                v-for="(subgroup, i) in group.subgroups"
                :key="i"
                class="bottom-gap"
                style="position: relative; top: 18px;"
              >
                <div class="bottom-gap">
                  <div
                    class="inline-middle"
                    style="width: 20px; border-bottom: 1px solid rgb(var(--v-theme-grey-darken3));"
                  />
                  <div
                    class="label inline-middle left-gap-sm"
                    :style="{ 'margin-bottom': loadingGroupValue
                      && subgroup.values
                      && subgroup.values.map(v => v.id).includes(loadingGroupValue) ? '3px' : '0px' }"
                  >
                    {{ i + 1 }}
                    <v-icon
                      v-if="loadingGroupValue
                        && subgroup.values
                        && subgroup.values.map(v => v.id).includes(loadingGroupValue)"
                      class="left-gap-sm"
                      color="primary"
                      size="20"
                    >
                      fas fa-spinner fa-pulse
                    </v-icon>
                    <v-icon
                      v-else-if="subgroup.values
                        && subgroup.values.length > 0
                        && subgroup.values.every(
                          value => value.reviewStatus === 'reviewed'
                        )"
                      class="valid-icon left-gap-sm inline-top"
                      style="margin-top: -2px"
                      size="16"
                    >
                      fas fa-check
                    </v-icon>
                    <v-tooltip
                      v-else-if="subgroup.values
                        && subgroup.values.length > 0
                        && subgroup.values.every(
                          value => value.reviewStatus === 'prevalidated'
                        )"
                      location="right"
                    >
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          class="small-button prevalidated-button left-gap-sm inline-middle"
                          color="primary"
                          :disabled="!subgroup.values || subgroup.values.length === 0"
                          @click="confirmSubgroup(subgroup)"
                        >
                          <v-icon size="16">
                            fas fa-check
                          </v-icon>
                        </v-btn>
                      </template>
                      {{ $t("dataPoints.subgroup_prevalidated_message") }}
                    </v-tooltip>
                    <v-btn
                      v-else
                      class="small-button left-gap-sm inline-middle"
                      color="primary"
                      :disabled="!subgroup.values || subgroup.values.length === 0"
                      @click="confirmSubgroup(subgroup)"
                    >
                      <v-icon size="16">
                        fas fa-check
                      </v-icon>
                    </v-btn>
                    <v-btn
                      v-if="subgroup.origin === 'manual'"
                      class="small-button left-gap-sm inline-middle"
                      color="primary"
                      @click="removeSubgroup(group, subgroup)"
                    >
                      <v-icon size="16">
                        fas fa-trash
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <ReviewTextArea 
                  v-for="value in subgroup.values"
                  :ref="`group_${value.id}`"
                  :key="`group_${value.id}`"
                  class="bottom-gap left-gap"
                  id-name="id"
                  :value="value"
                  :business-rule-strings="getSubgroupValueBRStrings(value.label, subgroup.id, group.group_id)"
                  :list-hovered-id="listHovered && listHovered.id ? listHovered.id : 0"
                  :viewer-hovered-id="viewerHovered && viewerHovered.id ? viewerHovered.id : 0"
                  :reviewing-id="reviewingValue && reviewingValue.id ? reviewingValue.id : 0"
                  :loading="loadingGroupValue === value.id"
                  @verify="verifyValue(value)"
                  @reinit="reinitValue(value)"
                  @focus="reviewingValue = value"
                  @hover="v => {
                    listHovered = v;
                    listHoveredSubgroup = subgroup;
                  }"
                  @blur="handleBlur"
                />
                <div
                  v-if="subgroup.values
                    && group.labelDisplayNames
                    && group.labelDisplayNames.some(
                      label => !subgroup.values.map(v => v.reviewName).includes(label)
                    )"
                  class="left-gap"
                >
                  <v-btn
                    v-if="!subgroup.addingLabel"
                    class="small-button"
                    color="primary"
                    @click="subgroup.addingLabel = true"
                  >
                    <v-icon size="16">
                      fas fa-plus
                    </v-icon>
                  </v-btn>
                  <div v-else>
                    <v-select
                      class="inline-middle"
                      style="width: 300px"
                      variant="outlined"
                      color="primary"
                      density="compact"
                      placeholder="Select label"
                      :items="group.labelDisplayNames.filter(
                        label => !subgroup.values.map(v => v.reviewName).includes(label)
                      )"
                      @update:model-value="label => addLabel(
                        group, label, subgroup,
                      )"
                    />
                    <v-icon
                      class="clickable"
                      size="16"
                      @click="subgroup.addingLabel = false"
                      end
                    >
                      fas fa-times
                    </v-icon>
                  </div>
                </div>
              </div>
              <div style="position: relative; top: 18px;">
                <div
                  class="inline-middle"
                  style="width: 20px; border-bottom: 1px solid rgb(var(--v-theme-grey-darken3));"
                />
                <v-btn
                  color="primary"
                  variant="outlined"
                  :disabled="group.subgroups
                    && group.subgroups.length > 0
                    && !group.subgroups[group.subgroups.length - 1].id"
                  @click="addEmptySubgroup(group)"
                  rounded
                >
                  <v-icon
                    size="16"
                    start
                  >
                    fas fa-plus
                  </v-icon>
                  {{ $t("dataPoints.add_missing_subgroup") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <v-skeleton-loader
              v-for="i in 10"
              :key="i"
              type="heading"
              width="100%"
              style="margin-left: -12px; margin-bottom: -20px !important"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="resize-divider"
      :style="{
        left: `${dividerPosition}%`
      }"
      @mousedown="startDragging"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 512"
        style="position:absolute; top: 50%; transform: translateY(-50%); fill: gray;"
      >
        <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64zm128 0c0-17.7-14.3-32-32-32s-32 14.3-32 32V448c0 17.7 14.3 32 32 32s32-14.3 32-32V64z" />
      </svg>
    </div>
    <DocViewer
      ref="docViewer"
      type="review"
      :style="{ width: `${100 - dividerPosition}% !important` }"
      :document="selectedFileInfo || defaultFileInfo"
      :document-type="docType"
      :loading="!selectedFileInfo"
      :text-select="!!reviewingValue || addingObject != -1"
      :external-token="$route.params.token"
      :object-values="objectValues"
      :review-values="viewerValues"
      :highlighted-object="highlightedObject"
      :highlighted-value="highlightedValue"
      @handle-object-enter="handleObjectEnter"
      @handle-object-leave="handleObjectLeave"
      @update-coordinates="selectValueFromViewer"
      @highlight="value => viewerHovered = value"
      @verify="value => verifyValue(value)"
      searchable
    />
    <DeleteDialog
      v-model="validationDialog"
      :title="$t('corrections.validate_document')"
      :message="$t('corrections.validate_confirmation')"
      @confirm="validateDocument"
      @close="validationDialog = false"
    />
    <DeleteDialog
      v-model="discardDialog"
      :title="$t('corrections.discard_document')"
      :message="$t('corrections.discard_confirmation')"
      @confirm="discardDocument"
      @close="discardDialog = false"
    />
    <DeleteDialog
      v-model="removeSubgroupDialog"
      :title="$t('datatable.deleteSubgroup')"
      :message="$t('datatable.deleteConfirmationSubgroup')"
      @confirm="confirmRemoveSubgroup"
      @close="removeSubgroupDialog = false"
    />
    <v-dialog
      v-model="copyDialog"
      max-width="400"
      @click:outside="copyDialog = false"
      @keydown.esc="copyDialog = false"
      @keydown.enter="copyToDataset"
    >
      <v-card class="dialog-card">
        <v-icon
          style="position: absolute; right: 10px; top: 10px;"
          color="black"
          size="17"
          @click="copyDialog = false"
        >
          fas fa-times
        </v-icon>
        <h2 class="dialog-title mb-3">
          {{ $t('corrections.copy') }}
        </h2>
        {{ $t('corrections.copy_confirm') }}
        <DatasetSelect
          class="top-gap-sm"
          :datasets="datasets"
          :selected="selectedDataset"
          @selected-changed="(id) => { selectedDataset = id }"
          @get-more="getDatasets(numberOfDatasets)"
        />
        <v-checkbox
          v-model="prefillAnnotations"
          color="primary"
          :label="$t('dataset.page.prefillAnnotations')"
        />
        <div
          v-if="warningMessage"
          class="warning-message-card top-gap"
        >
          <h4 class="label bottom-gap-sm">
            <v-icon
              class="mb-1"
              size="16"
            >
              fas fa-exclamation-triangle
            </v-icon>
            {{ $t('dataset.page.warning') }}
          </h4>
          <p align="justify">
            {{ warningMessage }}
          </p>
        </div>
        <div class="d-flex top-gap">
          <div class="dialog-button mr-2">
            <v-btn
              style="box-shadow: none"
              variant="outlined"
              @click="copyDialog = false"
              block
              rounded
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
          <div class="dialog-button ml-2">
            <v-btn
              style="box-shadow: none"
              color="primary"
              :disabled="selectedDataset === -1"
              @click="copyToDataset"
              block
              rounded
            >
              {{ $t('confirm') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import _ from 'lodash';
import jwtDecode from "jwt-decode";

import { http } from "@/plugins/axios";
import { BusinessRuleAPI } from '@/API/extract/BusinessRuleAPI';
import { ProductionFileAPI } from '@/API/extract/ProductionFileAPI';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import { VerifyAPI } from '@/API/extract/VerifyAPI';
import { AuthenticationUtils } from '@/utils/AuthenticationUtils';
import {
  getDataPointBRStrings,
  getGroupValueBRStrings,
} from '@/utils/BusinessRuleUtils';

import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import DocNavigator from "@/components/extract/elements/Documents/DocNavigator";
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import DatasetSelect from "@/components/extract/elements/Datasets/DatasetSelect";
import DocViewer from "@/components/extract/elements/DocViewer/DocViewer.vue";
import GroupTableDisplay from "@/components/extract/elements/Corrections/GroupTableDisplay";
import ObjectValidator from '@/components/extract/elements/Validation/ObjectValidator';
import ReviewTextArea from "@/components/extract/elements/Corrections/ReviewTextArea";

export default {
  name: 'ReviewDocView',

  components: {
    BackLink,
    DocNavigator,
    DeleteDialog,
    DatasetSelect,
    DocViewer,
    GroupTableDisplay,
    ObjectValidator,
    ReviewTextArea,
  },

  data() {
    return({
      dividerPosition: 50,
      maxPanelPercentage: 60,
      minLeftPanelWidth: 339,
      mouseDirection: '',
      reviewStopped: false,
      docType: {
        organized: false,
      },
      acceptedStatuses: [
        'to_verify',
        'in_verification',
        'verified'
      ],
      nullLocation: {
        "x_min": 0,
        "x_max": 0,
        "y_min": 0,
        "y_max": 0
      },
      defaultFileInfo: { id: -1, pages: [], },
      filesLoading: false,
      loadingGroupValue: 0,
      loadingDpValue: 0,
      addingObject: -1,
      groupLoadingEnded: false,
      dpLoadingEnded: false,
      selectedFileId: -1,
      selectedFileInfo: null,
      reviewingValue: null,
      listHovered: null,
      listHoveredSubgroup: null,
      viewerHovered: null,
      highlightedObject: {},
      highlightedValue: {},
      dpValues: [],
      objectValues: [],
      groupValues: [],
      datasets: [],
      totalDatasets: 0,
      selectedDataset: -1,
      prefillAnnotations: false,
      validationDialog: false,
      discardDialog: false,
      copyDialog: false,
      removeSubgroupDialog: false,
      expired: false,
      businessRules: [],
      ruleResults: {},
      dataPointBRStrings: {},
      groupValueBRStrings: {},
    });
  },

  computed: {
    menuOpen() {
      return this.$store.getters.menuOpen;
    },

    viewerValues() {
      const viewerGroupValues = [];
      this.groupValues.forEach(group => {
        if (group.subgroups) {
          group.subgroups.forEach(subgroup => {
            if (subgroup.values) {
              subgroup.values.forEach(value => {
                viewerGroupValues.push(value);
              });
            }
          });
        }
      });
      return [...this.dpValues, ...viewerGroupValues].filter(
        value => !_.isEqual(value.reviewLocation, this.nullLocation)
      );
    },

    loggedInUser() {
      const user = this.$store.getters.loggedInUser;
      if (user && user.role) {
        return user;
      }
      return null;
    },

    userRole() {
      if (this.loggedInUser && this.loggedInUser.role) {
        return this.loggedInUser.role;
      }
      return "external";
    },

    backTarget() {
      return {
        name: 'CorrectionDocs',
        params: {
          id: this.$route.params.id,
        },
      }
    },

    external() {
      return !!this.$route.params.token;
    },

    numberOfFiles() {
      return this.files.length;
    },

    files: {
      get() {
        return this.$store.getters.prodFiles;
      },
      set(files) {
        this.$store.commit('setProdFiles', files);
      },
    },

    totalFiles: {
      get() {
        return this.$store.getters.prodFilesTotal;
      },
      set(total) {
        this.$store.commit('setProdFilesTotal', total);
      },
    },

    selectedFileIndex: {
      get() {
        return this.$store.getters.prodFileIndex;
      },
      set(index) {
        this.$store.commit('setProdFileIndex', index);
      },
    },

    warningMessage() {
      const dataset = this.datasets.find(dataset => dataset.id === this.selectedDataset);
      const shareKeys = ["force_ocr", "custom_reading_order", "straighten_pages", "use_deskew"];
      if (dataset && shareKeys.some(key => dataset[key] !== this.docType[key])) {
        return this.$t('dataset.page.warningMessage');
      }
      return '';
    }
  },

  watch: {
    viewerHovered(newHovered, oldHovered) {
      if (newHovered && (!oldHovered || !_.isEqual(newHovered, oldHovered))
      ) {
        let valueComponentArr = null;
        if (newHovered.data_point_id) {
          valueComponentArr = this.$refs[`dp_${newHovered.data_point_id}`];
        } else {
          valueComponentArr = this.$refs[`group_${newHovered.id}`];
        }
        if (valueComponentArr && valueComponentArr.length > 0) {
          const valueComponent = valueComponentArr[0];
          valueComponent.$el.scrollIntoViewIfNeeded();
        }
      }
    },
  
    listHovered(newHovered, oldHovered) {
      if (newHovered
        && !_.isEqual(newHovered.reviewLocation, this.nullLocation)
        && (!oldHovered || !_.isEqual(newHovered, oldHovered))
      ) {
        const pageNb = newHovered.reviewPageNb || 1;
        const location = newHovered.reviewLocation;
        let subgroupLocation = null;
        if (!newHovered.data_point_id
          && this.listHoveredSubgroup
          && this.listHoveredSubgroup.values
        ) {
          const subgroupPageValues = this.listHoveredSubgroup.values.filter(
            (v) => {
              return (
                v && v.reviewLocation
                && !_.isEqual(v.reviewLocation, this.nullLocation)
                && newHovered.reviewPageNb === v.reviewPageNb
              )
            }
          );
          if (subgroupPageValues.length > 0) {
            subgroupLocation = {
              "x_min": Math.min.apply(Math, subgroupPageValues.map(v => v.reviewLocation.x_min)),
              "x_max": Math.max.apply(Math, subgroupPageValues.map(v => v.reviewLocation.x_max)),
              "y_min": Math.min.apply(Math, subgroupPageValues.map(v => v.reviewLocation.y_min)),
              "y_max": Math.max.apply(Math, subgroupPageValues.map(v => v.reviewLocation.y_max)),
            };
          }
        }
        this.highlightedValue = {
          pageNb,
          reviewStatus: newHovered.reviewStatus,
          location,
          subgroupLocation,
        }
      } else if (!newHovered) {
        this.highlightedValue = {};
      }
    },

    async selectedFileIndex(index) {
      if (!this.selectedFileInfo || this.selectedFileInfo.ordinal_pos !== index + 1) {
        let foundFile = this.files.find(f => f.ordinal_pos === index + 1);
        if (!foundFile) {
          await this.getFiles(index);
          foundFile = this.files.find(f => f.ordinal_pos === index + 1);
        }
        if (foundFile) {
          this.loadFilePage(foundFile.id);
        } 
      }
    },

    async selectedFileId(id) {
      if (!this.selectedFileInfo) {
        await this.getFileInfo(id);
        this.getAllRuleInfo();
      } else if (this.selectedFileInfo.id !== id) {
        this.loadFilePage(id);
      }
    },

    selectedFileInfo(info) {
      if (info) {
        this.startReview();
        this.selectedFileIndex = info.ordinal_pos - 1;
      }
    }
  },

  async created() {
    if (this.$route.params.id) {
      this.getDocType(this.$route.params.id);
    }
    if (this.external) {
      this.$store.commit('setMenu', false);
      const tokenDecoded = jwtDecode(this.$route.params.token);
      if (!tokenDecoded.org_id || !tokenDecoded.org_id) {
        this.$router.push({ name: "404Redirect" });
      }
      const now = new Date();
      this.expired =
        AuthenticationUtils.getExpDateFromToken(tokenDecoded) < now;
      if (!this.expired) {
        this.selectedFileId = parseInt(tokenDecoded.file_id, 10);
        this.orgId = tokenDecoded.org_id;
      } else {
        this.$router.push({ name: "404Redirect" });
      }
    } else {
      this.selectedFileId = parseInt(this.$route.params.fileId, 10);
      this.getFilesIfNeeded();
      if (this.userRole === 'orgadmin') {
        await this.getDatasets();
        if (this.numberOfDatasets > 0) {
          this.selectedDataset = this.datasets[0].id;
        }
      }
    }
    window.addEventListener("beforeunload", this.stopReview);
  },

  beforeUnmount() {
    if (this.selectedFileId > 0) {
      this.stopReview();
    }
    window.removeEventListener("beforeunload", this.stopReview);
  },

  methods: {
    async getAllRuleInfo() {
      await this.getBusinessRules();
      await this.getRuleResults();
      this.dataPointBRStrings = getDataPointBRStrings(
        this.businessRules,
        this.dpValues,
        this.ruleResults,
      );
      this.groupValueBRStrings = getGroupValueBRStrings(
        this.businessRules,
        this.groupValues,
        this.ruleResults,
      );
    },

    getSubgroupValueBRStrings(label, subgroupId, groupId) {
      const key = `eg_${groupId}_${label}_${subgroupId}`;
      return this.groupValueBRStrings[key] || [];
    },

    loadFilePage(id) {
      this.$router.push(
        {
          name: this.$route.name,
          params: {
            id: this.$route.params.id,
            fileId: id,
          }
        }
      );
    },

    startDragging() {
      document.addEventListener('mousemove', this.handleResize);
    },

    endDragging() {
      document.removeEventListener('mousemove', this.handleResize);
    },

    resizeDocViewer: _.throttle((filesDocViewer, mouseDirection) => {
      const zoom = mouseDirection === 'left' ? 0.32 : -0.32;
      filesDocViewer.handleZoom(zoom);
    }, 110),

    clearSelection() {
      if(document.selection && document.selection.empty) {
        document.selection.empty();
      } else if(window.getSelection) {
          var sel = window.getSelection();
          sel.removeAllRanges();
      }
    },

    handleResize(e = null) {
      if (e) {
        const sidebarWidth = this.menuOpen ? 250 : 61;
        const relativeX = e.pageX - sidebarWidth;
        const [parent,] = document.getElementsByClassName('review-doc-view');
        const parentWidth = parent.offsetWidth;
        const minWidth = this.minLeftPanelWidth + sidebarWidth;
        const percentage = (relativeX / parentWidth) * 100;
        if (
          relativeX >= minWidth
          && percentage <= this.maxPanelPercentage
          && percentage >= 10 && percentage <= 90
        ) {
          this.dividerPosition = percentage;
        }
        const { docViewer } = this.$refs;
        if (docViewer) {
          this.resizeDocViewer(docViewer, this.mouseDirection);
        }
      }
      this.clearSelection();
    },

    handleBlur() {
      const viewer = this.$refs.docViewer;
      if (!viewer || !viewer.drawing) {
        if (
          this.reviewingValue
          && (
            this.reviewingValue.reviewChanged
            || this.reviewingValue.reviewStatus !== 'pending'
            )
          ) {
          this.verifyValue(this.reviewingValue);
        }
        this.reviewingValue = null;
      }
    },


    handleObjectEnter(object){
      this.highlightedObject = object;
      this.$refs.docViewer.currentPage = object.page_nb;
    },

    handleObjectLeave(){
      this.highlightedObject = {};
    },

    handleAddObject(object_dp_id) {
      if (this.addingObject == -1){
        const dpObjectValues = this.objectValues
          .filter(item => item['object_dp_id'] === object_dp_id) 
          .map(item => item['object_values'])[0]; 

        dpObjectValues.push({"adding": true, "page_nb": this.$refs.docViewer.currentPage});
        this.addingObject = object_dp_id;
      }
    },

    cancelAddObject(object_dp_id) {
      const dpObject = this.objectValues
          .filter(item => item['object_dp_id'] === object_dp_id)[0];

      dpObject["object_values"] = dpObject["object_values"].filter(obj => !('adding' in obj));
      this.addingObject = -1;
    },

    async confirmGroupValues(group) {
      try {
        await VerifyAPI.confirmGroupValues(
          group.group_id, this.$route.params.token,
        );
        group.subgroups.forEach(subgroup => {
          subgroup.values.forEach(value => value.reviewStatus = 'reviewed')
        });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async confirmSubgroup(subgroup) {
      try {
        await VerifyAPI.confirmSubgroupValues(
          subgroup.id, this.$route.params.token,
        );
        subgroup.values.forEach(value => {
          value.reviewStatus = 'reviewed';
        });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async addMissingSubgroup(groupId, subgroup) {
      try {
        const newSubgroup = await VerifyAPI.postMissingSubgroup(
          groupId, this.selectedFileId, subgroup, this.$route.params.token
        );
        return newSubgroup.id;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async addMissingLabel(subgroupId, labelValue) {
      try {
        return await VerifyAPI.postMissingLabel(
          subgroupId, labelValue, this.$route.params.token,
        );
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async addLabel(group, labelDisplayName, subgroup, value = '') {
      const label = group.config.labels[
        group.labelDisplayNames.findIndex(l => l === labelDisplayName)
      ];
      subgroup.addingLabel = false;
      if (!subgroup.values) {
        subgroup.values = [];
      }
      const labelValue = [
        {
          confidence: 1,
          status: 'valid',
          value,
          location: this.nullLocation,
          label,
        }
      ]
      if (!subgroup.id) {
        subgroup.id = await this.addMissingSubgroup(group.group_id, {
          page_nb: 1,
          values: [],
        });
      }
      const valueIds = await this.addMissingLabel(subgroup.id, labelValue);
      labelValue[0].reviewValue = value;
      labelValue[0].reviewStatus = 'reviewed';
      labelValue[0].reviewLocation = this.nullLocation;
      labelValue[0].reviewPageNb = 1;
      labelValue[0].reviewKey = `group_${valueIds[0]}`;
      labelValue[0].reviewName = labelDisplayName;
      labelValue[0].id = valueIds[0];
      subgroup.values.push(labelValue[0]);
      if (group.config.table_display) {
        this.reviewingValue = labelValue[0];
      }
    },

    removeSubgroup(group, subgroup) {
      if (!subgroup.id) {
        group.subgroups.pop();
      } else {
        this.removingSubgroupInfo = [group, subgroup];
        this.removeSubgroupDialog = true;
      }
    },

    async confirmRemoveSubgroup() {
      try {
        const group = this.removingSubgroupInfo[0];
        const subgroup = this.removingSubgroupInfo[1];
        this.removingSubgroupInfo = null;
        await http.delete(`system_2/verify/groups/${subgroup.id}`,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          })
        group.subgroups = group.subgroups.filter(s => s.id !== subgroup.id);
        this.removeSubgroupDialog = false;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getGroupConfig(id) {
      try {
        const response = await http.get(`system_2/extraction_groups/${id}/`,
        {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async addEmptySubgroup(group) {
      if (!group.subgroups) {
        group.subgroups = [];
      }
      if (group.config.show_empty_primary) {
        const subgroupId = await this.addMissingSubgroup(group.group_id, {
          page_nb: 1,
          values: [],
        });
        group.subgroups.push({
          id: subgroupId,
          values: [],
          origin: 'manual',
        });
        this.getGroupValues(false, subgroupId);
      } else {
        group.subgroups.push({
          values: [],
          origin: 'manual',
        });
      }
    },

    async selectValueFromViewer(coordinates) {
      try {
        if (this.addingObject != -1){
          coordinates.status = "valid"
          await http.post(
            `system_2/verify/object_dp/${this.addingObject}`,
            coordinates,
            {
              params: {
                file_id: this.selectedFileInfo.id || this.defaultFileInfo.id,
                noAuth: this.external || false,
                external: this.external || false,
                token: this.external ? this.$route.params.token : null,
              }
            }
          );
          this.getObjectValues();
        }
        else{
          const pageNb = coordinates.page_nb;
          const response = await http.post(
            `production/files/${this.selectedFileId}/text/?page_nb=${pageNb}`,
            coordinates.location,
            {
              params: {
                noAuth: this.external || false,
                external: this.external || false,
                token: this.external ? this.$route.params.token : null,
              }
            }
          );
          if (response.data && response.data.text && response.data.location) {
            const { text, location } = response.data;
            this.reviewingValue.reviewValue = text;
            if (this.reviewingValue.data_point_id) {
              this.verifyDpValue(
                this.reviewingValue,
                text,
                pageNb,
                location,
              );
            } else {
              this.verifyGroupValue(
                this.reviewingValue,
                text,
                pageNb,
                location,
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        nextTick(() => {
          this.addingObject = -1;
          this.reviewingValue = null;
          this.$refs.docViewer.resetDrawing();
        });
      }
    },

    async getDocType(id) {
      try {
        const response = await http.get(`system_2/verify/document_type/${id}/`,
         {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        this.docType = response.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async verifyGroupValue(groupValue, textValue, pageNb, location) {
      try {
        this.startGroupLoading(groupValue.id);
        if (textValue === '') {
          pageNb = 1;
          location = this.nullLocation;
        }
        await http.post(
          `system_2/verify/${groupValue.id}/groups/`,
          {
            value: textValue,
            page_nb: pageNb,
            location,
          },
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        groupValue.reviewStatus = 'reviewed';
        groupValue.reviewLocation = location;
        groupValue.reviewPageNb = pageNb;
        await this.getGroupValues(true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.groupLoadingEnded = true;
      }
    },

    startDpLoading(id) {
      this.loadingDpValue = id;
      setTimeout(() => {
        if (this.dpLoadingEnded) {
          this.loadingDpValue = 0;
        } else {
          this.startDpLoading(id);
        }
      }, 1000);
    },

    startGroupLoading(id) {
      this.loadingGroupValue = id;
      setTimeout(() => {
        if (this.groupLoadingEnded) {
          this.loadingGroupValue = 0;
        } else {
          this.startGroupLoading(id);
        }
      }, 1000);
    },

    reinitValue(value) {
      if (value.data_point_id) {
        this.reinitDpValue(value);
      } else {
        this.reinitGroupValue(value);
      }
      this.getAllRuleInfo();
    },

    verifyValue(value) {
      value.reviewChanged = false;
      if ((typeof value.reviewValue === 'string'
        || value.reviewValue instanceof String)) {
        value.reviewValue = value.reviewValue.trim();
      }
      if (value.data_point_id) {
        this.verifyDpValue(
          value,
          value.reviewValue,
          value.reviewPageNb,
          value.reviewLocation,
        );
      } else {
        this.verifyGroupValue(
          value,
          value.reviewValue,
          value.reviewPageNb,
          value.reviewLocation,
        );
      }
      this.getAllRuleInfo();
    },

    async reinitDpValue(value) {
      try {
        await http.post(
          `system_2/verify/${this.selectedFileId}/${value.data_point_id}/reinitialize/`,
          {},
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        value.reviewValue = value.value.value;
        value.reviewLocation = value.value.location;
        value.reviewPageNb = value.value.page_nb;
        value.reviewStatus = 'pending';
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async reinitGroupValue(value) {
      try {
        await http.post(
          `system_2/verify/${value.id}/groups/reinitialize/`, {},
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        value.reviewValue = value.value;
        value.reviewLocation = value.location;
        value.reviewPageNb = value.page_nb;
        value.reviewStatus = 'pending';
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async verifyDpValue(dp, value, pageNb, location) {
      this.startDpLoading(dp.data_point_id);
      if (value === '') {
        pageNb = 1;
        location = this.nullLocation;
      }
      let payload = {
        value,
        page_nb: pageNb,
        location,
      };
      try {
        await http.post(
          `system_2/verify/${this.selectedFileId}/${dp.data_point_id}/`,
          payload,
          {
            params: {
              noAuth: this.external || false,
              external: this.external || false,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        dp.reviewStatus = 'reviewed';
        dp.reviewLocation = location;
        dp.reviewPageNb = pageNb;
        this.getDpValues(true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.dpLoadingEnded = true;
      }
    },

    getReviewValue(value) {
      if (value.status !== 'pending'
        && Object.prototype.hasOwnProperty.call(value, 'valid_value')
      ) {
        return value.valid_value || '';
      } else if (
        Object.prototype.hasOwnProperty.call(value, 'normalized_value')
      ) {
        return value.normalized_value;
      } else if (
        Object.prototype.hasOwnProperty.call(value, 'value')
      ) {
        return value.value || '';
      }
      return '';
    },

    getReviewStatus(value) {
      if (
        value
        && value.prevalidated
        && Object.prototype.hasOwnProperty.call(value, 'valid_value')
      ) {
        return 'prevalidated';
      } else if (value && value.prevalidated && value.status === 'invalid') {
        return 'pre-invalidated'
      } else if (value && value.status !== 'pending') {
        return 'reviewed';
      } else {
        return 'pending';
      }
    },

    getReviewLocation(reviewStatus, value) {
      if (['prevalidated', 'reviewed'].includes(reviewStatus)) {
        return value.valid_location || this.nullLocation;
      } else {
        return value.location || this.nullLocation;
      } 
    },

    getReviewPageNb(reviewStatus, value) {
      if (['prevalidated', 'reviewed'].includes(reviewStatus)) {
        return value.valid_page_nb || 1;
      } else {
        return value.page_nb || 1;
      } 
    },

    findSubgroup(id) {
      const totalGroupValues = this.groupValues.length;
      for (let i = 0; i < totalGroupValues; i++) {
        const group = this.groupValues[i];
        if (group.subgroups) {
          const totalSubgroups = group.subgroups.length;
          for (let j = 0; j < totalSubgroups; j++) {
            const subgroup = group.subgroups[j];
            if (subgroup.id === id) {
              return subgroup;
            }
          }
        }
      }
      return null;
    },

    findGroupValue(id) {
      const totalGroupValues = this.groupValues.length;
      for (let i = 0; i < totalGroupValues; i++) {
        const group = this.groupValues[i];
        if (group.subgroups) {
          const totalSubgroups = group.subgroups.length;
          for (let j = 0; j < totalSubgroups; j++) {
            const subgroup = group.subgroups[j];
            if (subgroup.values) {
              const totalValues = subgroup.values.length;
              for (let k = 0; k < totalValues; k++) {
                const value = subgroup.values[k];
                if (value.id === id) {
                  return value;
                }
              }
            }
          }
        }
      }
      return null;
    },

    async getGroupValues(insertPrevalidated = false, onlyForSubgroup = 0) {
      try {
        let { data } = await http.get(
          `production/files/${this.selectedFileId}/groups/`,
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        if (this.docType.organized) {
          data = data.sort((a, b) => {
            const aHasEmptyStatus = a.subgroups.some(subgroup => subgroup.values.some(value => value.status.trim().length === 0));
            const bHasEmptyStatus = b.subgroups.some(subgroup => subgroup.values.some(value => value.status.trim().length === 0));

            if (aHasEmptyStatus && !bHasEmptyStatus) {
              return -1;
            }
            if (!aHasEmptyStatus && bHasEmptyStatus) {
              return 1;
            }

            const aHasInvalidStatus = a.subgroups.some(subgroup => subgroup.values.some(value => value.status === 'invalid'));
            const bHasInvalidStatus = b.subgroups.some(subgroup => subgroup.values.some(value => value.status === 'invalid'));

            if (aHasInvalidStatus && !bHasInvalidStatus) {
              return -1;
            }
            if (!aHasInvalidStatus && bHasInvalidStatus) {
              return 1;
            }
            const aHasPendingStatus = a.subgroups.some(subgroup => subgroup.values.some(value => value.status === 'pending'));
            const bHasPendingStatus = b.subgroups.some(subgroup => subgroup.values.some(value => value.status === 'pending'));

            if (aHasPendingStatus && !bHasPendingStatus) {
              return -1;
            }
            if (!aHasPendingStatus && bHasPendingStatus) {
              return 1;
            }
            return 0;
          });
        }

        if (!insertPrevalidated && !onlyForSubgroup) {
          this.groupValues = data;
        }

        const totalGroupValues = data.length;
        if (!insertPrevalidated && !onlyForSubgroup) {
          for (let i = 0; i < totalGroupValues; i++) {
            const group = data[i];
            if (!group.config) {
              group.config = await this.getGroupConfig(group.group_id);
              group.labelDisplayNames = [];
              group.config.display_names.forEach((displayName, i) => {
                group.labelDisplayNames.push(displayName || group.config.labels[i]);
              });
            }
          }
          this.groupValues = [...data];
        }
        for (let i = 0; i < totalGroupValues; i++) {
          const group = data[i];
          if (group.subgroups) {
            group.subgroups.forEach(subgroup => {
              if (subgroup.values
                && (
                  !onlyForSubgroup
                  || (onlyForSubgroup && onlyForSubgroup === subgroup.id)
                  )
                ) {
                subgroup.values.forEach(value => {
                  if (insertPrevalidated && value.prevalidated) {
                    const existingValue = this.findGroupValue(value.id);
                    if (existingValue) {
                      existingValue.reviewValue = this.getReviewValue(value);
                      if (value.status === 'valid') {
                        existingValue.reviewStatus = 'prevalidated';
                      } else {
                        existingValue.reviewStatus = 'pre-invalidated';
                      }
                    }
                  } else {
                    value.reviewValue = this.getReviewValue(value);
                    value.reviewStatus = this.getReviewStatus(value);
                    value.reviewLocation = this.getReviewLocation(
                      value.reviewStatus, value,
                    );
                    value.reviewPageNb = this.getReviewPageNb(
                      value.reviewStatus, value,
                    );
                    value.reviewKey = `group_${value.id}`;
                    value.reviewName = value.label_display_name;
                  }
                });
                if (onlyForSubgroup === subgroup.id) {
                  let existingSubgroup = this.findSubgroup(subgroup.id);
                  existingSubgroup.values = subgroup.values;
                  return
                }
              }
            });
          }
        }
        if (!insertPrevalidated && !onlyForSubgroup) {
          this.groupValues = [...data];
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getDpValues(insertPrevalidated = false) {
      try {
        const response = await http.get(
          `production/files/${this.selectedFileId}/values/`,
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        const dpValues = this.docType.organized ? [...response.data.filter((dp) => dp.value)
          .sort((a, b) => {
            if (a.value.status.trim().length === 0 && b.value.status.trim().length !== 0) {
              return -1;
            }
            if (a.value.status.trim().length !== 0 && b.value.status.trim().length === 0) {
              return 1;
            }
            if (a.value.status === 'invalid' && b.value.status !== 'invalid') {
              return -1;
            }
            if (a.value.status !== 'invalid' &&  b.value.status === 'invalid') {
              return 1;
            }
            if (a.value.status === 'pending' && b.value.status !== 'pending') {
              return -1;
            }
            if (a.value.status !== 'pending' &&  b.value.status === 'pending') {
              return 1;
            }
            return 0;
          })] : [...response.data.filter((dp) => dp.value).sort(
            (a, b) => a.position - b.position
          )];
        if (!insertPrevalidated) {
          this.dpValues = dpValues.map(dp => {
            dp.reviewValue = this.getReviewValue(dp.value);
            dp.reviewStatus = this.getReviewStatus(dp.value);
            dp.reviewLocation = this.getReviewLocation(dp.reviewStatus, dp.value);
            dp.reviewPageNb = this.getReviewPageNb(dp.reviewStatus, dp.value);
            dp.reviewKey = `dp_${dp.data_point_id}`;
            dp.reviewName = dp.data_point_name;
            return dp;
          });
        } else {
          dpValues.forEach((dp) => {
            if (dp.value && dp.value.prevalidated) {
              const existingDp = this.dpValues.find(v => v.data_point_id === dp.data_point_id);
              if (existingDp) {
                existingDp.reviewValue = this.getReviewValue(dp.value);
                if (dp.value.status === 'valid') {
                  existingDp.reviewStatus = 'prevalidated';
                } else {
                  existingDp.reviewStatus = 'pre-invalidated';
                }
              }
            }
          });
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getObjectValues() {
      try {
        const response = await http.get(
          `production/files/${this.selectedFileId}/objects/`,
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            }
          }
        );
        let dbObjectValues = response.data;
        dbObjectValues.forEach((objectDpInfo, index) => {
          objectDpInfo['object_values'].forEach(ov => {
              ov.panelIndex = index;
          });
        });
        this.objectValues = dbObjectValues;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getBusinessRules(offset = 0, limit = 100) {
      try {
        if (offset === 0) {
          this.businessRules = [];
        }
        const [businessRules, total] = await BusinessRuleAPI.get(
          offset,
          limit,
          this.selectedFileInfo.document_type_id,
          '',
          true,
          this.external,
          this.external ? this.$route.params.token : null,
        );
        this.businessRules = [...this.businessRules, ...businessRules];
        if (this.businessRules.length < total) {
          await this.getBusinessRules(offset + limit, limit);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRuleResults() {
      try {
        const results = await BusinessRuleAPI.getRuleResults(
          this.selectedFileId,
          this.external,
          this.external ? this.$route.params.token : null,
        );
        this.ruleResults = results;
      } catch (error) {
        console.log(error);
      }
    },

    async copyToDataset() {
      this.$store.commit("setLoadingScreen", true);
      try {
        await http.post(
          `production/${this.selectedFileId}/copy_to/${this.selectedDataset}/`,
          {},
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
              prefill_annotations: this.prefillAnnotations,
            },
          }
        );
        this.$store.commit("setSuccessMessage", this.$t("corrections.copied"));
        this.$store.commit("setSuccessSnackbar", true);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.copyDialog = false;
        this.$store.commit("setLoadingScreen", false);
      }
    },

    async getDatasets(offset = 0, limit = 20) {
      if (offset && offset >= this.totalDatasets) {
        return;
      }
      try {
        const response = await DatasetAPI.get(
          offset,
          limit,
          '',
          null,
          true,
          this.external,
          this.external,
          this.external ? this.$route.params.token : null
        );
        if (offset > 0) {
          this.datasets = [...this.datasets, ...response.data];
        } else {
          this.datasets = response.data;
        }
        this.totalDatasets = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    getFilesIfNeeded() {
      const prevRoute = this.$router.options.history.state.back;
      if (!prevRoute
        || !prevRoute.startsWith(
        `/suite/production/review/${this.$route.params.id}/file`)
        || this.files.length === 0
      ) {
        this.$store.commit('setMenu', false);
        this.files = [];
        this.getFiles();
      }
    },

    validateDocumentIfNoPendingValues() {
      const noPending =
        this.dpValues.every((value) => value.reviewStatus !== "pending") &&
        this.groupValues.every((group) => {
          return group.subgroups.every((subgroup) => {
            return subgroup.values.every((value) => value.reviewStatus !== "pending");
          });
        });
      if (!noPending) {
        this.validationDialog = true;
      } else {
        this.validateDocument();
      }
    },

    async discardDocument() {
      this.$store.commit("setLoadingScreen", true);
      try {
        await http.put(
          `production/files/${this.selectedFileId}/discard/`,
          {},
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        this.reviewStopped = true;
        window.removeEventListener("beforeunload", this.stopReview);
        if (this.external) {
          this.finishExternalReview();
        } else {
          this.getNextFile();
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.discardDialog = false;
        this.$store.commit("setLoadingScreen", false);
      }
    },

    getNextFile() {
      this.files.splice(this.selectedFileIndex, 1);
      this.totalFiles--;
      if (this.selectedFileIndex == this.totalFiles) {
        this.selectedFileIndex = 0;
      } else {
        const numberOfFiles = this.numberOfFiles;
        for (let i = this.selectedFileIndex; i < numberOfFiles; i++) {
          if (this.files[i].ordinal_pos) {
            this.files[i].ordinal_pos--;
          }
        }
      }
      if (this.totalFiles === 0) {
        this.$router.push(this.backTarget);
      } else {
        this.loadFilePage(this.files[this.selectedFileIndex].id);
      }
    },

    async validateDocument() {
      this.$store.commit("setLoadingScreen", true);
      try {
        await http.put(
          `production/files/${this.selectedFileId}/validate/`,
          {},
          {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        this.reviewStopped = true;
        window.removeEventListener("beforeunload", this.stopReview);
        if (this.external) {
          this.finishExternalReview();
        } else {
          this.getNextFile();
        }
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      } finally {
        this.validationDialog = false;
        this.$store.commit("setLoadingScreen", false);
      }
    },

    finishExternalReview() {
      this.stopReview();
      this.$store.commit("setSuccessMessage", this.$t("corrections.success"));
      this.$store.commit("setSuccessSnackbar", true);
      setTimeout(() => { this.$router.push({name: 'SuiteLogin'}) }, 2000);
    },

    startReview() {
      http.post(`system_2/verify/start_verification/${this.selectedFileId}/`, {},
        {
          params: {
            noAuth: this.external,
            external: this.external,
            token: this.external ? this.$route.params.token : null,
          },
        }
      );
    },

    async stopReview() {
      if (this.reviewStopped) {
        return;
      }
      try {
        await http.post(
          `system_2/verify/stop_verification/${this.selectedFileId}/`, {},
         {
            params: {
              noAuth: this.external,
              external: this.external,
              token: this.external ? this.$route.params.token : null,
            },
          }
        );
        this.reviewStopped = true;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },

    async getFileInfo(id) {
      try {
        const info = await ProductionFileAPI.getSingle(
          id, true, this.$route.params.token,
        );
        if (
          (!this.external && info.document_type_id !== parseInt(this.$route.params.id, 10))
          || !this.acceptedStatuses.includes(info.status)
        ) {
          this.$router.push({ name: '404Redirect' });
        } else {
          this.selectedFileInfo = info;
          const fileIndex = this.selectedFileInfo.ordinal_pos - 1;
          if (this.files.length < fileIndex + 1 || this.files[fileIndex].type === 'placeholder') {
            this.getFiles(fileIndex);
          }
          this.getDpValues();
          this.getGroupValues();
          this.getObjectValues();
        }
      } catch (err) {
        this.$router.push({ name: '404Redirect' });
      }
    },

    async getMissingFiles() {
      this.filesLoading = true;
      const offset = this.files.findIndex(f => f.type && f.type === 'placeholder');
      if (offset > -1) {
        await this.getFiles(offset);
      }
    },

    async getFiles(offset = 0, limit = 20) {
      if (this.external || (this.numberOfFiles && offset && offset >= this.totalFiles)) {
        return;
      }
      try {
        this.filesLoading = true;
        const response = await ProductionFileAPI.get(
          this.$route.params.id, offset, limit, null, true, false,
        );
        this.totalFiles = parseInt(response.headers['x-total-count'], 10);
        if (this.numberOfFiles === 0) {
          this.files = Array(this.totalFiles).fill({ id: -1, type: 'placeholder' });
        }
        const start = this.files.slice(0, offset);
        const end = this.files.slice(offset + limit);
        this.files = [...start, ...response.data, ...end];
      } catch (err) {
        console.log(err);
      } finally {
        this.filesLoading = false;
      }  
    },
  }
}
</script>

<style lang="scss" scoped>
.review-doc-view {
  height: 100vh;
  position: relative;

  .left-field {
    height: 100vh;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    overflow: auto;
    position: relative;
    padding: 20px;
    padding-left: 20px;
    padding-top: 13px;
    -webkit-box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));
    -moz-box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));
    box-shadow: inset -1px 0px 0px 0px rgb(var(--v-theme-grey-darken2));
  }

  .valid-icon {
    color: $valid-base !important;
  }

  .prevalidated-button {
    background-color: $prevalidated-correct !important;
  }
}

.dialog-button {
  width: 100%;
}

.resize-divider {
  height: 100vh;
  width: 6px;
  background: #fff;
  transform: translateX(-3px);
  position: absolute;
  top: 0;
  z-index: 1000;
  cursor: ew-resize;
}

.object-validator-container {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
</style>
